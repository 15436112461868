import React from 'react'
import { useSelector } from 'react-redux'
import { Icon, queryParameters } from '@nl/lib'
import { PREFIX } from '../../config'
import { string } from 'prop-types'

import { AutoServiceButtonProps } from './AutoServiceButton.type'
import { commonContentAvailableSelector, commonContentSelector } from '../../redux/selectors/commonContent.selectors'
import { AOALinkTypes, getAOAWorkFlowQueryParams } from '../../helpers/aoaWorkflow.helper'
import { useAemTogglesContext } from '../ProductCardAndBillingWrapper/AEMToggleContext'
import { orderConfirmationAOAUrlEnabledSelector } from '../../redux/selectors/orderItemsDetails.selectors'

const AutoServiceButton: React.FC<AutoServiceButtonProps> = ({ ...props }): JSX.Element => {
    const { storeNumberForFrame } = props

    const { commonContentAvailable } = useSelector(commonContentSelector)

    const { autoAppointment } = useSelector(commonContentAvailableSelector)

    const orderAOAConfirmationEnabled = useSelector(orderConfirmationAOAUrlEnabledSelector)

    const { autoServiceButtonLabel, autoAppointmentPagePath, autoServiceButtonAriaLabel } =
        autoAppointment || ({} as typeof commonContentAvailable.autoAppointment)

    const { aoaConfirmationFlowEnabledToggle } = useAemTogglesContext()

    /**
     * function to return auto service button redirection link
     * @return string
     */

    const autoServiceLink = (): string => {
        const queryParams = new URLSearchParams()

        const { orderReferenceNumber } = getAOAWorkFlowQueryParams()
        const { orderId, store, aoaLinkType } = queryParameters

        if (orderReferenceNumber && aoaConfirmationFlowEnabledToggle && orderAOAConfirmationEnabled) {
            queryParams.set(orderId, orderReferenceNumber)
            queryParams.set(aoaLinkType, AOALinkTypes.OrderPage)
        }

        if (storeNumberForFrame) {
            queryParams.set(store, storeNumberForFrame)
        }

        const query = queryParams.toString()

        return query ? autoAppointmentPagePath + '?' + query : autoAppointmentPagePath
    }

    return (
        <a
            className={`${PREFIX}-need-auto-service-button`}
            data-testid="auto-appointment-button"
            href={autoServiceLink()}
            aria-label={autoServiceButtonAriaLabel}>
            <Icon type={props.icon} size={props.iconSize} />
            {autoServiceButtonLabel}
        </a>
    )
}

AutoServiceButton.defaultProps = {
    icon: 'ct-wrench-in-green',
    iconSize: 'md',
}

AutoServiceButton.propTypes = {
    storeNumberForFrame: string,
}

AutoServiceButton.displayName = 'AutoServiceButton'

export default AutoServiceButton
