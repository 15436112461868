import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { storeDetailsDataSelector } from '../../redux/selectors/storeDetails.selectors'
import { ShopTheLookComponentProps } from './ShopTheLookComponent.type'
import { fetchRecommendationsData } from '../../redux/actions'
import { PREFIX } from '../../config'
import { shopTheLook, options } from './ShopTheLookComponent.constant'
import ImageContainer from './ImageContainer/ImageContainer'
import ProductCardsSkeleton from './ProductCardsContainer/ProductCardsSkeleton'
import { RootState } from '../../redux/reducers'
import ProductCardsContainer from './ProductCardsContainer/ProductCardsContainer'
import HeaderContainer from './HeaderContainer/HeaderContainer'
import { recommendationAnalytics } from '../../analytics/components/recommendationAnalytics'
import { analyticsAttributes } from '../../globalConstants'
import { ProductCardType, RecommendationResponseDataDTO } from '../../redux/models/recommendations.interface'
import { ProductDataTypeObj } from '../../redux/models/productData.interface'
import { useMobileLayoutState, useDesktopLayoutState } from '@nl/lib'
import { getNumberOfTiles, matchedProductsList } from './ShopTheLook.helper'

const componentName = `${PREFIX}-shop-the-look-component`

/**
 * ShopTheLookComponent
 * @param ShopTheLookComponentProps ShopTheLookComponentProps
 * @returns : JSX.Element | null
 */
const ShopTheLookComponent: React.FC<ShopTheLookComponentProps> = ({ ...props }): JSX.Element | null => {
    const dispatch = useDispatch()

    const { recommendationsData } = useSelector((state: RootState) => state.recommendationsData)
    const { preferredStoreDetails } = useSelector(storeDetailsDataSelector)

    const [loading, setLoading] = useState(true)
    const [isInViewport, setIsInViewport] = useState(false)

    const prevStoreInfoIdRef = useRef(preferredStoreDetails.id)
    const ShopTheLookComponentWrapper = useRef(null)
    const [isMobileLayout] = useMobileLayoutState() as [boolean]
    const [isDesktopLayout] = useDesktopLayoutState() as [boolean]
    const { firstSection: firstComponent, secondSection: secondComponent } = props
    const firstNumberOfTiles = Number(firstComponent.numberOfTiles)
    const secondNumberOfTiles = Number(secondComponent.numberOfTiles)
    const firstComponentWidthType = getNumberOfTiles(firstNumberOfTiles)
    const productListFirst = matchedProductsList(firstNumberOfTiles, firstComponent)
    const secondComponentWidthType = getNumberOfTiles(secondNumberOfTiles)
    const productListSecond = matchedProductsList(secondNumberOfTiles, secondComponent)
    const productListCodeFirst = productListFirst.map(elem => elem.productCode)
    const productListCodeSecond = productListSecond.map(elem => elem.productCode)
    const productList = [...productListCodeFirst, ...productListCodeSecond]
    const isHideSecondSection = secondComponent.hide && !isDesktopLayout
    const hideSecondSectionClass = isHideSecondSection ? `${PREFIX}-hide-second-section` : ''
    const sectionTitleValue = props.title || 'shop-the-look'

    const dispatchAction = useCallback(() => {
        if (isInViewport) {
            if (prevStoreInfoIdRef.current !== preferredStoreDetails.id) {
                dispatch(fetchRecommendationsData(productList, recommendationsData, shopTheLook))
                prevStoreInfoIdRef.current = preferredStoreDetails.id
                setLoading(false)
            }
            setIsInViewport(false)
        }
    }, [dispatch, isInViewport, preferredStoreDetails.id, productList, recommendationsData])

    useEffect(() => {
        const shopTheLookObserver = new IntersectionObserver(([entry], _observer) => {
            if (entry.isIntersecting) {
                dispatchAction()
                setIsInViewport(true)
                _observer.disconnect()
            }
        }, options)
        if (ShopTheLookComponentWrapper.current) {
            shopTheLookObserver.observe(ShopTheLookComponentWrapper.current)
        }
        return () => {
            shopTheLookObserver.disconnect()
        }
    }, [dispatchAction, ShopTheLookComponentWrapper])

    /**
     * This function would help to fire an analytic layer when a product is clicked from recommendation list.
     * @param {ProductCardType | ProductDataTypeObj} productData productData
     * @param {string} type type
     * @returns {void} returns void
     */
    const productCardClickHandler = (productData: ProductCardType | ProductDataTypeObj, type: string): void => {
        const productCardData =
            recommendationsData.find((products: RecommendationResponseDataDTO) => products.scheme === shopTheLook)
                ?.productData || []
        recommendationAnalytics(
            analyticsAttributes.event.productClick,
            productCardData,
            shopTheLook,
            type,
            0,
            productData,
        )
    }

    return props.showComponent ? (
        <div
            ref={ShopTheLookComponentWrapper}
            className={`${componentName}`}
            role="tabpanel"
            aria-label={`${props.title || ''} ${props.description || ''}`}
            tabIndex={0}
            style={{ backgroundColor: props.backgroundColour }}>
            <HeaderContainer title={props.title} description={props.description} fontColour={props.fontColour} />
            <div className={`${componentName}-wrapper ${hideSecondSectionClass}`}>
                <div className={'first-half-component'}>
                    <ImageContainer
                        imageLinkTarget={firstComponent?.imageLinkTarget}
                        imageLink={firstComponent?.imageLink}
                        desktopTabletImage={firstComponent?.desktopTabletImage}
                        mobileImage={firstComponent?.mobileImage}
                        imageAltText={firstComponent?.imageAltText}
                        imageAreaLabel={firstComponent?.imageAreaLabel}
                        imageLinkAnalyticsTrackingId={firstComponent?.imageLinkAnalyticsTrackingId}
                        componentWidthType={firstComponentWidthType}
                        isMobileLayout={isMobileLayout}
                        sectionTitle={sectionTitleValue}
                    />
                    {loading ? (
                        <ProductCardsSkeleton
                            productCardCount={firstNumberOfTiles}
                            componentWidthType={`${firstComponentWidthType}`}
                        />
                    ) : (
                        <ProductCardsContainer
                            componentWidthType={firstComponentWidthType}
                            productCardCount={firstNumberOfTiles}
                            title={shopTheLook}
                            productListCode={productListCodeFirst}
                            productsList={productListFirst}
                            productCardClickHandler={productCardClickHandler}
                            sectionTitle={sectionTitleValue}
                            enableMiniPdpFlyoutSupport={props.enableQuicklookATC}
                        />
                    )}
                </div>
                {isHideSecondSection ? null : (
                    <div className={'second-half-component'}>
                        <ImageContainer
                            imageLinkTarget={secondComponent?.imageLinkTarget}
                            imageLink={secondComponent?.imageLink}
                            desktopTabletImage={secondComponent?.desktopTabletImage}
                            mobileImage={secondComponent?.mobileImage}
                            imageAltText={secondComponent?.imageAltText}
                            imageAreaLabel={secondComponent?.imageAreaLabel}
                            imageLinkAnalyticsTrackingId={secondComponent?.imageLinkAnalyticsTrackingId}
                            componentWidthType={secondComponentWidthType}
                            isMobileLayout={isMobileLayout}
                            sectionTitle={sectionTitleValue}
                        />
                        {loading ? (
                            <ProductCardsSkeleton
                                productCardCount={secondNumberOfTiles}
                                componentWidthType={`${secondComponentWidthType}`}
                            />
                        ) : (
                            <ProductCardsContainer
                                componentWidthType={secondComponentWidthType}
                                productCardCount={secondNumberOfTiles}
                                title={shopTheLook}
                                productListCode={productListCodeSecond}
                                productsList={productListSecond}
                                productCardClickHandler={productCardClickHandler}
                                sectionTitle={sectionTitleValue}
                                enableMiniPdpFlyoutSupport={props.enableQuicklookATC}
                            />
                        )}
                    </div>
                )}
            </div>
        </div>
    ) : null
}

export default ShopTheLookComponent
