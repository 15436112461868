import { CriteoCustomParams } from './criteoService.constants'
import { ProductDataObj } from '../../components/ProductGridView/ProductCard.types'
import { ProductCardType } from '../../redux/models/recommendations.interface'
import {
    CriteoParams,
    CriteoProduct,
    CriteoResponse,
    ModifiedCriteoPlacement,
    ModifiedCriteoResponse,
    ParsedRenderingAttr,
    SponsoredProduct,
} from './criteo.interface'
import { CriteoEventType, ModifiedPlacementKey, OriginalPlacementKey } from './criteoService.constants'

/**
 * Appends multiple key-value pairs to a URLSearchParams object.
 * @param {URLSearchParams} params - The URLSearchParams object to append to.
 * @param {Array<[string, string | number | undefined]>} keyValues - An array of key-value pairs to append.
 * @returns {URLSearchParams} The updated URLSearchParams object.
 */
const appendParams = (
    params: URLSearchParams,
    keyValues: Array<[string, string | number | undefined]>,
): URLSearchParams => {
    keyValues.forEach(([key, value]) => {
        if (value) {
            params.append(key, value.toString())
        }
    })
    return params
}

/**
 * @param {CriteoParams} params - The criteo API parameters
 * @returns {string} The url params containing all the custom information
 */
export const appendCustomCriteoParams = (params: CriteoParams): string => {
    const { eventType, pageId, isMobile } = params
    const deviceType = isMobile ? 'mobile' : 'desktop'
    const customParams = new URLSearchParams()
    customParams.append(CriteoCustomParams.EVENT_TYPE, eventType)
    customParams.append(CriteoCustomParams.PAGE_ID, `${pageId}_API_${deviceType}`)

    switch (eventType) {
        case CriteoEventType.VIEW_SEARCH_RESULT:
            appendParams(customParams, [
                [CriteoCustomParams.KEYWORDS, params.keywords],
                [CriteoCustomParams.PAGE_NUMBER, params.pageNumber],
            ])
            break

        case CriteoEventType.VIEW_CATEGORY:
            appendParams(customParams, [
                [CriteoCustomParams.CATEGORY, params.category],
                [CriteoCustomParams.PAGE_NUMBER, params.pageNumber],
            ])
            break

        case CriteoEventType.VIEW_ITEM:
            appendParams(customParams, [
                [CriteoCustomParams.PARENT_ITEM, params.parentItem],
                [CriteoCustomParams.PRICE, params.price],
                [CriteoCustomParams.AVAILABILITY, params.isAvailable ? '1' : '0'],
                [CriteoCustomParams.CATEGORY, params.category],
            ])
            break

        default:
            break
    }

    // breakdown URLSearchParam into an array and join them into one string to prevent unnecessary encoding of the parameters
    const customParamsArray = Array.from(customParams.entries()).map(([key, value]) => `${key}=${value}`)

    return customParamsArray.join('&')
}

/**
 * Converts an array of Criteo products to an array of sponsored product data objects.
 * @param {CriteoProduct[]} criteoProducts - An array of products from Criteo.
 * @returns {ProductDataObj[]} An array of sponsored product data objects.
 */
export const convertCriteoData = (criteoProducts: CriteoProduct[]): SponsoredProduct[] => {
    return criteoProducts.map((criteoProduct: CriteoProduct) => {
        const {
            ProductName,
            ProductPage,
            ParentSKU,
            Image,
            RenderingAttributes,
            OnLoadBeacon,
            OnViewBeacon,
            OnBasketChangeBeacon,
        } = criteoProduct
        const { clearance, url } = JSON.parse(RenderingAttributes) as ParsedRenderingAttr

        return {
            title: ProductName,
            url: url || ProductPage,
            code: ParentSKU,
            images: [{ url: Image }],
            badges: clearance === 'Y' ? ['CLEARANCE'] : [],
            isSponsored: true,
            onLoadBeacon: OnLoadBeacon,
            onViewBeacon: OnViewBeacon,
            onBasketChangeBeacon: OnBasketChangeBeacon,
            skus: [],
        }
    })
}

/**
 * Attaches product details to a list of Criteo products.
 * @param {SponsoredProduct[]} sponsoredProducts - An array of SponsoredProducts.
 * @param {ProductCardType[]} productCards - An array of ProductCardType objects.
 * @returns {ProductDataObj[]} - An array of Product Cards with attached details.
 */
export const attachProductDetails = (
    sponsoredProducts: SponsoredProduct[],
    productCards: ProductCardType[],
): ProductDataObj[] => {
    const products: ProductDataObj[] = sponsoredProducts.map(sponsoredProduct => {
        const details = productCards.find(item => item.code === sponsoredProduct.code)

        // make sure we remove duplicate badges
        const combinedBadges = [details?.badges, sponsoredProduct.badges].flat().filter(Boolean)
        const uniqueBadges = combinedBadges.filter((value, index) => combinedBadges.indexOf(value) === index)

        return {
            ...sponsoredProduct,
            badges: uniqueBadges,
            rating: Number(details?.rating),
            ratingsCount: Number(details?.ratingsCount) || 0,
            currentPrice: details?.currentPrice,
            originalPrice: details?.originalPrice,
            priceMessage: details?.priceMessage,
            displayWasLabel: details?.displayWasLabel,
            isMultiSku: details?.isMultiSku,
            sellable: details?.sellable,
            orderable: details?.orderable,
            fulfillment: details?.fulfillment,
            skus: details?.skus,
            brand: details?.brand,
            isOnSale: details?.isOnSale,
        }
    })

    return products
}

/**
 * Rename Criteo placement keys to just `inGrid` and `carousel`
 * @param {CriteoResponse} response The original response from Criteo API
 * @returns {ModifiedCriteoResponse} modified object
 */
export const renamePlacementKeys = (response: CriteoResponse): ModifiedCriteoResponse => {
    const simplifiedPlacements = Object.fromEntries(
        response.placements
            .map(placement => {
                const placementKey = Object.keys(placement)[0] as OriginalPlacementKey

                // Convert the keys to lowercase
                const inGrid = ModifiedPlacementKey.IN_GRID.toLocaleLowerCase()
                const carousel = ModifiedPlacementKey.CAROUSEL.toLocaleLowerCase()
                const key = placementKey.toLocaleLowerCase()

                // Determine the simplified key based on the original placement key
                let simplifiedKey: ModifiedPlacementKey
                if (key.includes(inGrid)) {
                    simplifiedKey = ModifiedPlacementKey.IN_GRID
                } else if (key.includes(carousel)) {
                    simplifiedKey = ModifiedPlacementKey.CAROUSEL
                } else {
                    return null // Skip unknown placement keys
                }

                const placementValue = placement[placementKey][0]
                return [
                    simplifiedKey,
                    {
                        ...placementValue,
                        products: convertCriteoData(placementValue.products),
                    },
                ]
            })
            .filter(entry => entry !== null), // Filter out nulls from the skipped keys
    )

    return {
        status: response.status,
        onLoadBeacon: response.onLoadBeacon,
        'page-uid': response['page-uid'],
        placements: simplifiedPlacements as {
            [x in ModifiedPlacementKey]: ModifiedCriteoPlacement
        },
    }
}
