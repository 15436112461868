import React, { useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { anyDividerRegExp, getParameterFromURL, queryParameters, useIsMobile } from '@nl/lib'

import { selectedPreferredStoreIdSelector } from '../../redux/selectors/storeDetails.selectors'
import { productDataStateSelector } from '../../redux/selectors/productData.selectors'
import { constantValues } from '../ProductGridView/ProductCard.constant'
import appCacheService from '../../utils/appCacheService'
import { featureFlagSelector } from '../../redux/selectors/commonContent.selectors'
import { sponsoredAdsStateSelector } from '../../redux/selectors/sponsoredAds.selectors'
import getPageType from '../../utils/getPageType'
import { pageTypes } from '../../config'
import { idSelector as categoryIdSelector } from '../../redux/selectors/categoryIdData.selectors'
import {
    isAuthFlowExecutedSelector,
    signOutSuccessSelector,
    userProfileUID,
} from '../../redux/selectors/userProfile.selectors'
import { getEnvironment } from '../../environments'
import { fetchPDPSponsoredAds, fetchSponsoredAds } from '../../redux/actions/sponsoredAds.action'
import { setSponsoredAdsLoading } from '../../redux/actionCreators/sponsoredAds.actionCreators'
import localStorageService from '../../utils/localStorageService'
import { storageData } from '../../globalConstants'
import { getRetailerVisitorId } from './CriteoInit.helper'
import {
    carouselEligiblePages,
    CriteoEventType,
    CriteoPageId,
    inGridEligiblePages,
} from '../../services/criteoService/criteoService.constants'
import { productSelector } from '../../redux/selectors/product.selectors'
import { CriteoUniversalParams, CriteoViewItemParams } from '../../services/criteoService/criteo.interface'

const environment = getEnvironment()

/**
 * Component that will initialize Criteo Service
 * @returns {null} returns null
 */
export const CriteoInit: React.FC = () => {
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const customerId = useSelector(userProfileUID)
    const {
        inGrid,
        isLoading: isAdsLoading,
        isSuccess: isAdsSuccess,
        isError: isAdsError,
    } = useSelector(sponsoredAdsStateSelector)
    const { productCardLoading, productCardData } = useSelector(productDataStateSelector)
    const featureFlag = useSelector(featureFlagSelector)
    const currentCategoryId = useSelector(categoryIdSelector)
    const currentPageType = getPageType()
    const isAuthFlowExecuted = useSelector(isAuthFlowExecutedSelector)
    const isSignOutSuccess = useSelector(signOutSuccessSelector)
    const pdpProduct = useSelector(productSelector)
    const mountedRef = useRef(true)

    const selectedPreferredStoreId = useSelector(selectedPreferredStoreIdSelector)
    const storeId = selectedPreferredStoreId || appCacheService.preferredStoreId.get()

    const gigyaJWT = localStorageService.getItem(storageData.gigyaJWT)
    const isAuthenticationPending = Boolean(gigyaJWT && !isAuthFlowExecuted)
    const { enableCriteoDirectServer } = featureFlag

    const { baseUrl, partnerId, noLog } = environment.CRITEO_CONFIG
    const retailerVisitorId = getRetailerVisitorId()

    const keywords = getParameterFromURL(queryParameters.searchQuery, anyDividerRegExp) as string

    /**
     * Flag to determine if we need to fetch InGrid sponsored ads data.
     * The following conditions must be met:
     *  1. The `enableCriteoDirectServer` must be true.
     *  2. The page must be either a search results page or a category page.
     *  3. Authentication flow must be completed if `gigyaJWT` is present.
     *  4. The search filter must be disabled (checked via URL parameter 'q1').
     *  5. The InGrid data must be either empty or productCard is loading (`productCardLoading`).
     * @param {boolean} enableCriteoDirectServer - Flag indicating if Criteo direct server is enabled.
     * @param {Array} inGrid.products - Array of products in the InGrid.
     * @param {string} currentPageType - The current page type (e.g., search results page or category page).
     * @param {boolean} isAuthenticationPending - Flag indicating if authentication is pending.
     * @param {boolean} productCardLoading - Flag indicating if the product card is loading.
     * @param {Array<string>} inGridEligiblePages - Array of page types eligible for InGrid ads.
     * @param {Function} getParameterFromURL - Function to get a parameter from the URL.
     * @returns {boolean} - Whether to fetch InGrid sponsored ads data.
     */
    const shouldFetchInGridData = useMemo((): boolean => {
        if (!enableCriteoDirectServer) return false

        const isSearchFilterEnabled = Boolean(getParameterFromURL('q1'))
        const isInGridDataInitialized = Boolean(inGrid?.products?.length)
        const isPageEligible = inGridEligiblePages.includes(currentPageType)

        return (
            isPageEligible &&
            !isAuthenticationPending &&
            !isSearchFilterEnabled &&
            (!isInGridDataInitialized || productCardLoading)
        )
    }, [enableCriteoDirectServer, inGrid, currentPageType, isAuthenticationPending, productCardLoading])

    /**
     * Effect to manage the loading state of sponsored ads based on authentication status.
     * If authentication is pending, the loading state is set to true. Once authentication
     * is completed, the loading state is set to false.
     */
    useEffect(() => {
        if (!enableCriteoDirectServer) return

        if (isAuthenticationPending && !isSignOutSuccess) {
            dispatch(setSponsoredAdsLoading(true))
        } else if (isAuthFlowExecuted) {
            dispatch(setSponsoredAdsLoading(false))
        }
    }, [dispatch, isAuthFlowExecuted, gigyaJWT, isAuthenticationPending, isSignOutSuccess, enableCriteoDirectServer])

    /**
     *  Dispatch calls to Criteo API to fetch Sponsored Ads for pages
     *  that uses ProductGrid component e.g. SRP and CLP
     */
    useEffect(() => {
        if (!shouldFetchInGridData || !mountedRef.current) return

        const pageNumber = Number(getParameterFromURL(queryParameters.page) || constantValues.currentPageInitialValue)

        const isSearchPage = currentPageType === pageTypes.searchPage
        const isCategoryPage = pageTypes.categoryPages.includes(currentPageType)

        if (isSearchPage && keywords) {
            dispatch(
                fetchSponsoredAds(
                    CriteoEventType.VIEW_SEARCH_RESULT,
                    CriteoPageId.VIEW_SEARCH_RESULT,
                    baseUrl,
                    partnerId,
                    noLog,
                    retailerVisitorId,
                    storeId,
                    isMobile,
                    pageNumber,
                    keywords,
                    customerId,
                ),
            )
        } else if (isCategoryPage && currentCategoryId) {
            const pageId =
                currentPageType === pageTypes.plp ? CriteoPageId.VIEW_CATEGORY : CriteoPageId.VIEW_MERCHANDISING
            dispatch(
                fetchSponsoredAds(
                    CriteoEventType.VIEW_CATEGORY,
                    pageId,
                    baseUrl,
                    partnerId,
                    noLog,
                    retailerVisitorId,
                    storeId,
                    isMobile,
                    pageNumber,
                    currentCategoryId,
                    customerId,
                ),
            )
        }
    }, [
        dispatch,
        shouldFetchInGridData,
        currentCategoryId,
        storeId,
        isMobile,
        currentPageType,
        keywords,
        baseUrl,
        partnerId,
        noLog,
        retailerVisitorId,
        customerId,
    ])

    /**
     * Effect that sets the mountedRef.current to true when the component is mounted,
     * and sets it to false when the component is unmounted.
     */
    useEffect(() => {
        mountedRef.current = true

        return () => {
            mountedRef.current = false
        }
    }, [])

    /**
     * Determines whether to fetch Carousel sponsored ads data.
     * The following conditions must be met:
     *  1. The `enableCriteoDirectServer` must be true.
     *  2. Authentication flow must not be pending (`isAuthenticationPending` must be false).
     *  3. There is no ongoing request to fetch Criteo data (`isAdsLoading` must be false).
     *  4. The request to Criteo must not have succeeded yet (`isAdsSuccess` must be false).
     *  5. The request to Criteo must not have failed (`isAdsError` must be false).
     * @param {boolean} enableCriteoDirectServer - Flag indicating if Criteo direct server is enabled.
     * @param {boolean} isAdsSuccess - Flag indicating that the request to Criteo is successful.
     * @param {boolean} isAdsError - Flag indicating that the request to Criteo failed.
     * @param {boolean} isAdsLoading - Flag indicating if ads data is currently being fetched.
     * @param {boolean} isAuthenticationPending - Flag indicating if authentication is pending.
     * @returns {boolean} - Whether to fetch Carousel sponsored ads data.
     */
    const shouldFetchCarouselData = useMemo(() => {
        if (!enableCriteoDirectServer) return false

        return !isAdsSuccess && !isAdsError && !isAdsLoading && !isAuthenticationPending
    }, [enableCriteoDirectServer, isAdsError, isAdsLoading, isAdsSuccess, isAuthenticationPending])

    /**
     * Effect that triggers a Criteo Null Search if the search resulted in 0 products.
     */
    useEffect(() => {
        if (productCardData?.resultCount !== 0 || !enableCriteoDirectServer) return

        dispatch(
            fetchSponsoredAds(
                CriteoEventType.VIEW_SEARCH_RESULT,
                CriteoPageId.VIEW_NULL_SEARCH_RESULT,
                baseUrl,
                partnerId,
                noLog,
                retailerVisitorId,
                storeId,
                isMobile,
                0,
                keywords,
                customerId,
            ),
        )
    }, [
        baseUrl,
        customerId,
        dispatch,
        enableCriteoDirectServer,
        isMobile,
        keywords,
        noLog,
        partnerId,
        productCardData?.resultCount,
        retailerVisitorId,
        storeId,
    ])

    /**
     * Effect that triggers a Criteo call to fetch carousel data for eligible pages
     */
    useEffect(() => {
        if (!shouldFetchCarouselData || !carouselEligiblePages.includes(currentPageType)) return

        const { isOutOfStock, productData } = pdpProduct
        const price = productData?.currentPrice?.value?.toString() ?? ''

        if (!price || !productData?.code) return

        // get the category id
        const dcCodes = productData.breadcrumbList.map(item => item.categoryId).filter(Boolean)
        const category = dcCodes.length > 0 ? dcCodes[dcCodes.length - 1] : ''

        const baseParams: CriteoUniversalParams = {
            eventType: CriteoEventType.VIEW_ITEM,
            pageId: CriteoPageId.VIEW_ITEM,
            criteoBaseUrl: baseUrl,
            partnerId,
            noLog,
            retailerVisitorId,
            storeId,
            isMobile,
            customerId,
        }

        const viewItemParams: CriteoViewItemParams = {
            isAvailable: !isOutOfStock,
            parentItem: productData.code,
            price,
            category,
        }

        dispatch(fetchPDPSponsoredAds(baseParams, viewItemParams))
    }, [
        baseUrl,
        currentPageType,
        customerId,
        dispatch,
        isMobile,
        noLog,
        partnerId,
        pdpProduct,
        retailerVisitorId,
        shouldFetchCarouselData,
        storeId,
    ])

    return null
}
