import { AnyAction, Dispatch, Store } from 'redux'
import { RootState } from '../reducers'
import { ProductDataType } from '../models/productData.interface'
import { fetchProductCardDataSuccessAction } from '../actionCreators'
import { FETCH_PRODUCT_SUCCESS } from '../types/products/productData.actionTypes.constant'
import { FETCH_SPONSORED_ADS_SUCCESS } from '../types/products/sponsoredAds.actionTypes.constant'
import { ModifiedCriteoResponse } from '../../services/criteoService/criteo.interface'
import mergeSponsoredProducts from '../utils/mergeSponsoredProducts'
import { criteoIgnoredFilters } from '../../config'

interface IMergeSearchAdResultMiddleware {
    (store: Store<RootState, AnyAction>): (next: Dispatch<AnyAction>) => (action: AnyAction) => void
}

/**
 * Checks if any filters or range facets are selected in the payload except ignored filters
 * @param {ProductDataType} payload - The product data containing facets and filter selections.
 * @param {string[]} ignoredFilters - Array of filter IDs to ignore.
 * @returns {boolean} - True if any filters or range facets are selected, otherwise false.
 */
const isAnyFilterSelected = (payload: ProductDataType, ignoredFilters: string[]) => {
    const isAnyFacetSelected = payload.facets?.some(item => item.selected && !ignoredFilters?.includes(item.id))
    const isAnyRangeFacetSelected = payload.rangeFacets?.some(
        item => item.selected && !ignoredFilters?.includes(item.id),
    )

    return (
        payload.inStockAtMyStore?.selected ||
        payload.saleItem?.selected ||
        isAnyFacetSelected ||
        isAnyRangeFacetSelected
    )
}

/**
 * Middleware that merges products and sponsored ads when certain actions are dispatched, ensuring ads are merged unless filters are selected.
 * This logic is executed only when the `enableCriteoDirectServerOn` feature flag is enabled.
 * Both `enableCriteoDirectServer` and `enableCriteoDirectServerOnCDS` cannot be true simultaneously.
 * If this scenario occurs, the merging logic will be bypassed.
 *
 * Deprecation Notice: This middleware will be removed once all banners have transitioned to server-side Criteo calls.
 * Server side calls to criteo will continue to use `mergeSponsoredFromSearchMiddleware`.
 * @param {object} store - The Redux store instance.
 * @returns {Function} - The next middleware function.
 */
// eslint-disable-next-line complexity
export const mergeProductsAndSponsoredAdsMiddleware: IMergeSearchAdResultMiddleware = store => next => action => {
    const {
        commonContent,
        productCardData: { productCardData, productCardLoading },
        sponsoredAds,
        userProfile,
        categoryIdData,
    } = store.getState()

    const {
        commonContentAvailable: {
            featureFlag: { enableCriteoDirectServer = false, enableCriteoDirectServerOnCDS = false } = {},
        } = {},
    } = commonContent

    // Validate the feature flag configuration
    if (
        (enableCriteoDirectServer && enableCriteoDirectServerOnCDS) ||
        !enableCriteoDirectServer ||
        (categoryIdData?.isFitmentRequired && userProfile?.vehicle?.isTireOrDefaultVehicleSet)
    ) {
        return next(action)
    }

    if (action.type === FETCH_PRODUCT_SUCCESS && sponsoredAds.inGrid?.products?.length) {
        const originalPayload = action.payload as ProductDataType

        // Abort merging if one or more filters are selected
        if (isAnyFilterSelected(originalPayload, criteoIgnoredFilters)) {
            return next(action)
        }

        const modifiedAction = {
            ...action,
            payload: {
                ...originalPayload,
                products: mergeSponsoredProducts(sponsoredAds.inGrid.products, originalPayload.products),
            } as ProductDataType,
        }

        return next(modifiedAction)
    }

    if (action.type === FETCH_SPONSORED_ADS_SUCCESS && productCardData.products && !productCardLoading) {
        const {
            placements: { inGrid },
        } = action.payload as ModifiedCriteoResponse

        const productCardsWithInGridAds = {
            ...productCardData,
            products: mergeSponsoredProducts(inGrid.products, productCardData.products),
        } as ProductDataType

        store.dispatch(fetchProductCardDataSuccessAction(productCardsWithInGridAds))
    }

    return next(action)
}
