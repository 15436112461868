import React from 'react'
import { getFeeTitle } from './PriceComponent.helper'
import { pdpStaggeredTotalPrice } from '../../AutomotivePDP/AutomotiveBuyBox/AutomotiveBuyBox.helper'
import { Price, scrollToFooter, totalCurrentPrice, SkeletonComponent, libUtils } from '@nl/lib'
import { PriceMessage } from '@nl/lib/src'
import { isWheelOrTirePDP } from '../../Vehicles/Vehicle.helper'
import { checkDataLength } from '../../Accounts/Addresses/checkDataLength'
import { hasSaleClearanceBadge } from '../BuyBox.helper'
import { useGenerateSaveMessage } from '../../../helpers/useGenerateSaveMessage'
import { useSelector } from 'react-redux'
import { commonContentAvailableSelector } from '../../../redux/selectors/commonContent.selectors'
import {
    IAccessibility,
    IAutomotive,
    IFeatureFlag,
    IGeneral,
    IProduct,
} from '../../../redux/models/commonContent.interface'
import { PREFIX } from '../../../config'
import { productSelector } from '../../../redux/selectors/product.selectors'
import { enableDestructOnUndefinedData } from '../../../utils/PDP/enableDestructOnUndefinedData.utils'
import { calculatePriceType, PriceComponentProps, PriceType } from './PriceComponent.type'
import { ProductResponseData, ProductSku } from '../../../redux/models/product.interface'

/**
 * Component decides to render Price or skeleton based on
 * if sku present - wait till PandA api completes
 * until it gets the sku level detail show a skeleton
 * else if sku not present - render price component with product level price details
 * @param {PriceComponentProps} props PriceComponentProps props
 * @returns {JSX.Element} PriceComponent component
 */
const PriceComponent: React.FC<PriceComponentProps> = (props: PriceComponentProps): JSX.Element => {
    const {
        isStaggered,
        isAutomotive,
        isProductLevel,
        productDataBySku,
        setOfTwoLabel,
        setOfTwoNowLabel,
        staggeredFulfillmentSkusFiltered,
        staggeredFulfillmentSkus,
        isModalPresent,
        flyoutVariantsInfoData,
    } = props

    const {
        feeTitle,
        feeValue,
        originalPrice,
        currentPrice,
        displayWasLabel,
        discount,
        feeDisclaimerType,
        priceMessage,
        saleCut,
    } = productDataBySku

    const commonContentAvailable = useSelector(commonContentAvailableSelector)
    const generalProductData = useSelector(productSelector)
    const { productData, productSkuData, isSKUPresentInUrl, isPriceAvailabilityApiDone } =
        flyoutVariantsInfoData || generalProductData

    const {
        product: commonContentProduct = {} as IProduct,
        accessibility = {} as IAccessibility,
        featureFlag = {} as IFeatureFlag,
        general = {} as IGeneral,
        automotive = {} as IAutomotive,
    } = commonContentAvailable
    const {
        nowFromLabel,
        saveFromLabel,
        wasFromLabel,
        fromLabel,
        thresholdValue,
        unitPriceLabel,
        promotionalPriceLabel,
        clearancePriceLabel,
        eachLabel,
        setOf4Label: setOfFourLabel,
        setOf4NowLabel: setOfFourNowLabel,
    } = commonContentProduct
    const {
        a11yStrikeOutPrice,
        a11yStrikeOutPriceRange,
        a11yCloseIconLabel: closeIconLabel,
        a11yTooltipIcon,
    } = accessibility

    const feeMessageValue = !isStaggered ? feeValue : pdpStaggeredTotalPrice(staggeredFulfillmentSkus, true)
    const language = libUtils.getLanguage()
    const saveMessage = useGenerateSaveMessage(saleCut, originalPrice, currentPrice)
    const { feeMessages } = enableDestructOnUndefinedData(productData as ProductResponseData)

    /**
     * Function to check onsale value for regular products
     * @returns {boolean | undefined} onsale value for regular products
     */
    const automotiveProductIsSaleValue = (): boolean | undefined => {
        if (!isProductLevel && !isStaggered) {
            return productSkuData?.skus?.[0]?.isOnSale
        } else if (isProductLevel) {
            return productData?.isOnSale
        }
    }

    /**
     * Function to calculate price based on the type of PDP
     * @param {PriceType} price price
     * @returns {PriceType} price
     */
    const calculatePriceProp: calculatePriceType = (price: PriceType): PriceType => {
        if (isStaggered) {
            return pdpStaggeredTotalPrice(staggeredFulfillmentSkus) as PriceType
        } else if (isProductLevel) {
            return productData?.totalCurrentPrice as PriceType
        } else {
            return totalCurrentPrice([], price)
        }
    }

    return (isSKUPresentInUrl && isPriceAvailabilityApiDone) || !isSKUPresentInUrl ? (
        <Price
            unitPriceLabel={unitPriceLabel}
            clearancePriceLabel={clearancePriceLabel}
            promotionalPriceLabel={promotionalPriceLabel}
            thresholdValue={thresholdValue}
            a11yStrikeOutPrice={a11yStrikeOutPrice}
            a11yStrikeOutPriceRange={a11yStrikeOutPriceRange}
            feeTitle={getFeeTitle(feeMessageValue, feeTitle, language)}
            feeDisclaimerMessage={feeMessages?.[0]?.feeDisclaimerMessage}
            feeDisclaimerTitle={feeMessages?.[0]?.feeDisclaimerTitle}
            originalPrice={originalPrice}
            currentPrice={currentPrice}
            displayWasLabel={displayWasLabel}
            discountValue={discount}
            language={language}
            priceMessage={priceMessage as PriceMessage[]}
            a11yRecycleFeesTriangleIcon={automotive.a11yRecycleFeesTriangleIcon}
            scrollToFooter={scrollToFooter}
            nowFromLabel={nowFromLabel}
            saveFromLabel={saveFromLabel}
            wasFromLabel={wasFromLabel}
            fromLabel={fromLabel}
            feeDisclaimerType={feeDisclaimerType}
            eachLabel={eachLabel}
            isAutomotiveEachLabel={isWheelOrTirePDP(productData?.productWheelType)}
            isWheelOrTirePDP={isWheelOrTirePDP(productData?.productWheelType)}
            totalCurrentPrice={calculatePriceProp(currentPrice)}
            totalOriginalPrice={calculatePriceProp(originalPrice as PriceType)}
            setOfFourLabel={setOfFourLabel}
            setOfFourNowLabel={setOfFourNowLabel}
            isStaggered={isStaggered}
            productSkus={productData?.skus as ProductSku[]}
            a11yTooltipIcon={a11yTooltipIcon}
            a11yCloseIconLabel={closeIconLabel}
            isWheelOrTire={isWheelOrTirePDP(productData?.productWheelType)}
            showFromNow={true}
            isRebate={checkDataLength(productData?.rebate)}
            showEachTextForSaveLabel={productData?.rebate?.iumapp}
            saveMessage={saveMessage}
            enablePdpPricingEnhancement={featureFlag.enablePdpPricingEnhancement}
            a11yClickToReadFootnote={accessibility.a11yClickToReadFootnote}
            isOnSaleOrClearance={
                isAutomotive ? automotiveProductIsSaleValue() : hasSaleClearanceBadge(productData?.badges as string[])
            }
            plusMinusSymbol={general.plusMinusSymbol}
            isProductLevelPDP={isProductLevel}
            staggeredfulfillmentData={staggeredFulfillmentSkusFiltered}
            setOfTwoLabel={setOfTwoLabel}
            setOfTwoNowLabel={setOfTwoNowLabel}
            isModalPresent={isModalPresent}
        />
    ) : (
        <SkeletonComponent skeletonClass={`${PREFIX}-buy-box__price-skeleton`} dataTestId="priceSkeleton" />
    )
}

export default PriceComponent
