import { ssoLoginHandler, enableSingleRegistration } from '../helpers/ciam.helper'
import GigyaService from '../services/gigyaService/gigya.service'

/**
 * Handles redirection based on URL search parameters.
 * This function checks for specific search parameters to determine
 * the type of redirect and whether additional context needs to be passed.
 * @returns {void}
 */
const handleRedirectionWithSessionVerification = (): void => {
    const urlParams = new URLSearchParams(window.location.search)
    const redirectCLP = urlParams.get('redirectToCLP')
    const redirectCRP = urlParams.get('redirectToCRP')
    const option = urlParams.get('option')
    const additionalContext = { loyalty: option, flow: 'registration' }

    if (redirectCLP) {
        redirectWithSessionVerify()
    } else if (redirectCRP) {
        redirectWithSessionVerify(additionalContext)
    }
}

/**
 * Performs redirection after verifying the user session via Gigya service.
 * If the session is verified, redirects to a specific URL and handles
 * single sign-on (SSO) process with optional additional context.
 * @param {Record<string, unknown>} [additionalContext] - Optional additional data
 * to pass to the SSO login handler, e.g., loyalty information.
 * @returns {void}
 */
const redirectWithSessionVerify = (additionalContext?: Record<string, unknown>): void => {
    // Hide body logic is also duplicated on AEM side to hide static content
    document.querySelector('body').style.display = 'none'

    const gigyaService = new GigyaService()
    gigyaService
        .verifySession()
        .then((isReady: boolean) => {
            if (isReady) {
                const redirectURL = `${window.location.origin}${getOptionRedirectURL(additionalContext?.loyalty)}`
                ssoLoginHandler(redirectURL, additionalContext)
            }
        })
        .catch(err => console.error(err))
}

const getOptionRedirectURL = (option: string): string => {
    if (!enableSingleRegistration().enableLoyaltyFlowCRP || !option) {
        return window.location.pathname
    }

    const optionsRedirectURLMap = {
        newLoyalty: window.ODP.globalLinks?.requestNewCard,
        linkLoyalty: window.ODP.globalLinks?.linkExisingCard,
        linkMasterCard: window.ODP.globalLinks?.linkMasterCard,
        none: window.ODP.globalLinks?.homePageLink,
    }

    return optionsRedirectURLMap[option] as string
}

export default handleRedirectionWithSessionVerification
