import { SkuData, SkuPriceDataResponse } from '../../services/skuPriceService/skuPriceService.type'
import { ProductSku } from '../models/product.interface'
import { ProductDataTypeObj } from '../models/productData.interface'

/**
 * Util function to merge SkuPrice API's data with product's skus
 * @param {SkuPriceDataResponse} skuPriceData data from SkuPrice API
 * @param {ProductDataTypeObj[]} products products to merge
 * @returns {ProductDataTypeObj[]} products with merged prices
 */
const mergeSkuPriceDataWithProducts = (
    skuPriceData: SkuPriceDataResponse,
    products: ProductDataTypeObj[],
): ProductDataTypeObj[] => {
    const skusPricesMap = new Map<string, SkuData>(
        Object.values(skuPriceData.productFamilies).flatMap(item =>
            item.flatMap(sku => sku.codes.map(code => [code, sku])),
        ),
    )

    return products.map(product => {
        return {
            ...product,
            skus: product.skus.map(sku => {
                const updatedSku = skusPricesMap.get(sku.code) as ProductSku
                return {
                    ...sku,
                    ...updatedSku,
                }
            }),
        }
    })
}

export default mergeSkuPriceDataWithProducts
