import { useMemo, useEffect, useState, useRef } from 'react'
import { getParameterFromURL, isArrayNotEmpty, queryParameters } from '@nl/lib'
import { constantValues } from './ProductCard.constant'
import { ProductDataTypeObj } from '../../redux/models/productData.interface'
import { useDispatch, useSelector } from 'react-redux'
import { fillPriceAndAvailability, fillSkuPrices } from '../../redux/actionCreators'
import priceAvailabilityService from '../../services/priceAvailabilityService'
import { Vehicle } from '@nl/lib/src'
import { defaultVehicleSelector } from '../../redux/selectors/userProfile.selectors'
import { selectedPreferredStoreIdSelector } from '../../redux/selectors/storeDetails.selectors'
import { isEasternBanner } from '../../utils/getCurrentBannerId'
import skuPriceService from '../../services/skuPriceService'

export const usePageNumber = () => {
    const pageNumberFromURL = Number(getParameterFromURL(queryParameters.page))

    const [currentPage, setCurrentPage] = useState(
        pageNumberFromURL > 0 ? pageNumberFromURL : constantValues.currentPageInitialValue,
    )
    const [initialPageNumber, setInitialPageNumber] = useState(currentPage)

    useEffect(() => {
        if (!pageNumberFromURL) {
            setCurrentPage(constantValues.currentPageInitialValue)
            setInitialPageNumber(constantValues.currentPageInitialValue)
        }
    }, [pageNumberFromURL, setCurrentPage])

    return { currentPage, initialPageNumber, setCurrentPage, setInitialPageNumber }
}

/**
 * @description hook to Intersect the product grid and perform priceAvailability call for products that in viewport
 * @param {boolean} featureFlag
 * @param {ProductDataTypeObj[]} products
 * @param {number} page
 * @param {boolean} productCardLoading
 * @return {Function}
 */
export const usePriceAvailabilityLazyLoad = (
    featureFlag: boolean,
    products: ProductDataTypeObj[],
    page: number,
    productCardLoading: boolean,
) => {
    const pCodesSet = useMemo(() => new Set<string>(), [])
    const observedPCodes = useMemo(() => new Set<string>(), [])
    const [requestPCodes, setRequestPCodes] = useState<string[]>([])
    const selectedPreferredStoreId = useSelector(selectedPreferredStoreIdSelector)
    const dispatch = useDispatch()

    const productsObserver = useMemo(
        () =>
            featureFlag
                ? new IntersectionObserver(
                      (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
                          const [first] = entries
                          const firstEntryTop = first.target.getBoundingClientRect().top

                          const observedProductRowPCodes = entries
                              .filter(productEntree => productEntree.isIntersecting)
                              .map(({ target }) => {
                                  observer?.unobserve(target)
                                  return target.id
                              })

                          const productsPerRow = entries.filter(
                              entry =>
                                  entry.isIntersecting && entry.target.getBoundingClientRect().top === firstEntryTop,
                          ).length

                          const pCodes = Array.from(pCodesSet)
                          const [lastObserved] = observedProductRowPCodes.slice(-1)
                          const cutIndex = pCodes.findIndex(pCode => pCode === lastObserved)

                          let nextRowPCodes: string[] = []
                          if (cutIndex !== -1 && productsPerRow) {
                              nextRowPCodes = pCodes.slice(cutIndex + 1, cutIndex + productsPerRow + 1)
                          }

                          const pCodesToRequest: string[] = []
                          observedProductRowPCodes.concat(nextRowPCodes).forEach(pCode => {
                              if (!observedPCodes.has(pCode)) {
                                  pCodesToRequest.push(pCode)
                                  observedPCodes.add(pCode)
                              }
                          })

                          setRequestPCodes(pCodesToRequest)
                      },
                      { root: null, rootMargin: '0px', threshold: 0.1 },
                  )
                : null,
        [pCodesSet, observedPCodes, featureFlag],
    )

    useEffect(() => {
        if (productCardLoading) {
            pCodesSet.clear()
            observedPCodes.clear()
            setRequestPCodes([])
        }
    }, [observedPCodes, pCodesSet, page, productCardLoading])

    useEffect(() => {
        if (isArrayNotEmpty(requestPCodes) && isArrayNotEmpty(products)) {
            const requestProducts = products.filter(({ code }) => requestPCodes.includes(code))
            setRequestPCodes([])

            if (isEasternBanner()) {
                priceAvailabilityService
                    .fetchPriceAvailabilityData(requestProducts, selectedPreferredStoreId)
                    .then(priceAvailabilityData => {
                        dispatch(fillPriceAndAvailability(priceAvailabilityData))
                    })
                    .catch(error => {
                        console.error(error)
                    })
            } else {
                skuPriceService
                    .fetchSkuPriceData(requestProducts, selectedPreferredStoreId)
                    .then(skuPriceData => dispatch(fillSkuPrices(skuPriceData)))
                    .catch(error => console.error(error))
            }
        }
    }, [requestPCodes, products, dispatch, selectedPreferredStoreId])

    /**
     * This useEffect will disconnect observer when ProductCart is destoyed
     */
    useEffect(() => {
        return () => {
            productsObserver?.disconnect()
        }
    }, [productsObserver])

    const observeProductByRef = (node: HTMLElement | null) => {
        if (productsObserver && node && !pCodesSet.has(node.id)) {
            productsObserver.observe(node)
            pCodesSet.add(node.id)
        }
    }

    return featureFlag ? observeProductByRef : null
}

/**
 * Returns the fist vehicle snapshot with same id. It prevent extra call when vehicle is dependency and images is loaded first time
 * @return {Vehicle}
 */
export const useDefaultVehicleWithoutImageBlinking = (): Vehicle | undefined => {
    const defaultVehicle = useSelector(defaultVehicleSelector)
    const stableVehicle = useRef(defaultVehicle)
    if (!stableVehicle.current || !defaultVehicle || defaultVehicle?.id !== stableVehicle.current?.id) {
        stableVehicle.current = defaultVehicle
    }

    return stableVehicle.current
}
