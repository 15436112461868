import { ReduxStateType, SeoStructure } from '../components/Hoc/Seo.types'
import { categorySuffixByLang, vehicleSuffix, seoDescriptionLimit, pageTypes } from '../../config'
import { seoHelperConstants, SpecificPageTypes } from './Seo.helper.types'
import extractPCodeFromUrl from '../../utils/PDP/extractPCodeFromUrl'
import { getParameterFromURL, isArrayNotEmpty, libUtils, queryParameters, sanitizeStringContent } from '@nl/lib'
import { Vehicle } from '@nl/lib/src/components/ProductGridView/ProductGrid.types'
import { checkDataLength } from '../../components/Accounts/Addresses/checkDataLength'
import sessionStorageService from '../../utils/sessionStorageService'
import { FeatureBulletsDTO } from '../../redux/models/product.interface'
import { IGlobalProps } from '../../redux/models/commonContent.interface'
import { GlobalPropsHelper } from '../../analytics/helpers/globalProps/globalProps.helper'
import { TireType } from '../../redux/models/tireVehicle.interface'
import { isTirePDP, generateVehicleTitle, getFullTireSize } from '../../components/Vehicles/Vehicle.helper'

/**
 * Helper class to set the SEO values
 */
export class SeoHelper {
    static currentPdpUrl = window.location.href

    /**
     * function to return pcode url for canonical tag
     * @param {ReduxStateType} reduxState
     * @param {boolean} isStorePage
     * @param {boolean} isPreselectedFromURL
     * @returns {string}
     */
    static getcanonicalUrl(reduxState: ReduxStateType, isStorePage: boolean, isPreselectedFromURL: boolean): string {
        const currentHost = window.location.origin

        if (reduxState?.canonicalUrl) return `${currentHost}${reduxState.canonicalUrl}`
        if (isStorePage && reduxState?.url) return `${currentHost}${reduxState.url}`
        if (isPreselectedFromURL) return this.currentPdpUrl

        // get the canonical URL from current selected data
        const hrefLangUrls = reduxState?.hreflangLinkData || reduxState?.url
        const language = libUtils.getLanguage()
        const currentHrefLang = Array.isArray(hrefLangUrls)
            ? hrefLangUrls?.find(hrefLangUrl => hrefLangUrl.hreflang?.toLowerCase() === language.toLowerCase())
            : undefined
        const canonicalURLFromHrefLang = currentHrefLang?.url ? `${currentHost}${currentHrefLang?.url}` : null

        const { skuCode } = extractPCodeFromUrl()
        const canonicalForSku = this.currentPdpUrl.replace(`.${skuCode}`, '')

        if (Boolean(Number(skuCode))) {
            return canonicalForSku
        }
        if (!!canonicalURLFromHrefLang) {
            return canonicalURLFromHrefLang
        }
        return this.currentPdpUrl
    }

    /**
     * function to truncate the string
     * @param {string} str
     * @param {number} n
     * @returns {string}
     */
    static truncate(str: string, n: number): string {
        return str?.length > n ? str.substr(0, n) : str
    }

    /**
     * Function to generate url with vehicle specific parameters if its available
     * TODO:
     * This function is added as part of SEO POC in absence of the updated URL in category response
     * In case this logic is handled at ATLAS + Hybris layer at later point in time then kindly remove
     * this function and replace it with the url from response
     * @param {string} url
     * @param {string} hreflang
     * @param {boolean} isPreselectedFromURL
     * @returns {string}
     */
    static prepareVehicleSpecificUrl(url: string, hreflang: string, isPreselectedFromURL: boolean): string {
        if (isPreselectedFromURL) {
            const language = libUtils.getLanguage().toLowerCase() as keyof typeof categorySuffixByLang
            const categorySuffixByLanguage = categorySuffixByLang[language]
            const categorySuffixByHreflang = categorySuffixByLang[hreflang as keyof typeof categorySuffixByLang]
            return `${url?.split('.html')[0]}${vehicleSuffix}${categorySuffixByHreflang}${
                this.currentPdpUrl.split(`${vehicleSuffix}${categorySuffixByLanguage}`)[1]
            }`
        }
        return url
    }

    /**
     * function to get href lang tags
     * @param {SeoStructure} seoStructure
     * @param {ReduxStateType} reduxState
     * @param {boolean} isPreselectedFromURL
     */
    static getHrefLangTags(
        seoStructure: SeoStructure,
        reduxState: ReduxStateType,
        isPreselectedFromURL: boolean,
    ): void {
        const hrefLangUrls = reduxState?.hreflangLinkData || reduxState?.url
        const currentHost = window.location.origin
        if (Array.isArray(hrefLangUrls) && isArrayNotEmpty(hrefLangUrls)) {
            hrefLangUrls?.forEach(hrefLangUrl => {
                const { href, hreflang, url } = hrefLangUrl
                const currentLanguageObject = {
                    href: encodeURI(
                        `${currentHost}${this.prepareVehicleSpecificUrl(
                            (href || url) as string,
                            hreflang.toLowerCase(),
                            isPreselectedFromURL,
                        )}`,
                    ),
                    rel: 'alternate',
                    hreflang:
                        hreflang === seoHelperConstants.defaultHreflang
                            ? hreflang
                            : `${hreflang}${seoHelperConstants.canadaSuffix}`,
                }
                seoStructure?.links?.push(currentLanguageObject)
            })
        }
    }

    /**
     * function to get meta robot content
     * @param {ReduxStateType} reduxState
     * @returns {string}
     */
    static getMetaRobotContent(reduxState: ReduxStateType): string {
        if (reduxState?.noIndex && reduxState?.noFollow) {
            return seoHelperConstants.noIndexNoFollow
        } else if (reduxState?.noIndex) {
            return seoHelperConstants.noIndex
        } else if (reduxState?.noFollow) {
            return seoHelperConstants.nofollow
        }
        return ''
    }

    /**
     * function to get meta robot content
     * @param {ReduxStateType} reduxState
     * @param {boolean} isStorePage
     * @returns {string}
     */
    static getMetaDescription(reduxState: ReduxStateType, isStorePage: boolean): string {
        const concatinatedFeatures = []
        if (reduxState?.featureBullets) {
            const featureDescArr = reduxState?.featureBullets
            concatinatedFeatures.push(
                featureDescArr.map((featureDescription: FeatureBulletsDTO) => featureDescription.description),
            )
        }
        // TODO this needs to be consistent, API should always return seoDescription and not seodescription
        if (reduxState?.longDescription) {
            return reduxState?.longDescription
        } else if (reduxState?.seodescription || reduxState?.seoDescription) {
            return reduxState?.seodescription || reduxState?.seoDescription || ''
        } else if (isStorePage) {
            return reduxState?.seoDescription || reduxState?.seoTitle || ''
        } else return concatinatedFeatures.join()
    }

    /**
     * function to check is automotive plp page and vehicle information is saved
     * @param {Vehicle} defaultVehicle
     * @param {boolean} isFitmentRequired
     * @returns {boolean}
     */
    static isAutoPLPAndVehicleSelected(defaultVehicle: Vehicle, isFitmentRequired: boolean): boolean {
        return isFitmentRequired && checkDataLength(defaultVehicle)
    }

    /**
     * function to return PageTitle, Description, MetaTags
     * @param {ReduxStateType} reduxState
     * @param {SpecificPageTypes} specificPages
     * @param {Vehicle} defaultVehicle
     * @param {boolean} isFitmentRequired
     * @param {TireType} shopByTireSize
     * @param {Vehicle} urlDefaultVehicle
     * @returns {string | undefined}
     */
    static getPageTitle(
        reduxState: ReduxStateType,
        specificPages: SpecificPageTypes,
        defaultVehicle: Vehicle,
        isFitmentRequired: boolean,
        shopByTireSize: TireType,
        urlDefaultVehicle: Vehicle,
    ): string | undefined {
        const selectedVehicle = urlDefaultVehicle || defaultVehicle
        const vehicleTitle = generateVehicleTitle(selectedVehicle)
        const saveTireSize = shopByTireSize || {}
        const { selectedDiameterValue } = saveTireSize
        const fullTireSize = getFullTireSize(saveTireSize)

        if (specificPages.isPdpPage) {
            return reduxState?.name
        } else if (specificPages.isCategoryPage) {
            if (shopByTireSize !== null && isTirePDP(reduxState?.productWheelType)) {
                return `${selectedDiameterValue}" ${String(fullTireSize)} ${reduxState?.seoTitle as string}`
            } else if (this.isAutoPLPAndVehicleSelected(selectedVehicle, isFitmentRequired)) {
                return `${vehicleTitle} ${reduxState?.seoTitle as string}`
            } else {
                return reduxState?.seoTitle
            }
        } else if (specificPages.isStorePage) {
            return reduxState?.seoTitle || reduxState?.displayName
        }
        return undefined
    }

    /**
     *
     * @param {ReduxStateType} reduxState
     * @param {boolean} isStorePage
     * @returns {boolean}
     */
    static getDescription(reduxState: ReduxStateType, isStorePage: boolean): boolean {
        // TODO this needs to be consistent, API should always return seoDescription and not seodescription
        return (
            Boolean(reduxState?.longDescription) ||
            Boolean(reduxState?.featureBullets) ||
            Boolean(reduxState?.seodescription) ||
            Boolean(reduxState?.seoDescription) ||
            (isStorePage && (Boolean(reduxState?.seoDescription) || Boolean(reduxState?.seoTitle)))
        )
    }

    /**
     *
     * @param {ReduxStateType} reduxState
     * @returns {boolean}
     */
    static getMetaTags(reduxState: ReduxStateType): boolean {
        return (reduxState?.noIndex || reduxState?.noFollow) as boolean
    }

    /**
     * function to prepare meta description
     * @param {ReduxStateType} reduxState
     * @param {SpecificPageTypes} specificPages
     * @param {SeoStructure} seoStructure
     */
    static prepareMetaDescription(
        reduxState: ReduxStateType,
        specificPages: SpecificPageTypes,
        seoStructure: SeoStructure,
    ): void {
        seoStructure.metaName = seoStructure.metaName || ([] as unknown as [Record<string, string>])
        if (SeoHelper.getDescription(reduxState, specificPages.isStorePage)) {
            seoStructure?.metaName.push({
                name: 'description',
                content: this.truncate(
                    this.getMetaDescription(reduxState, specificPages.isStorePage),
                    seoDescriptionLimit,
                ),
            })
        } else {
            seoStructure?.metaName.push({
                name: 'description',
                content: '',
            })
        }
    }

    /**
     * function to get siteBannerTitle
     * @returns {string}
     */
    static getSiteBannerTitle(): string {
        const globalProps = new GlobalPropsHelper()
        const seometadata = globalProps.readDataAlternateLangDetails()
        return seometadata.siteBannerTitle
    }

    /**
     * function to get isAlternateTagRenderEnabled
     * @returns {boolean}
     */
    static isAlternateTagRenderEnabled(): boolean {
        const globalProps = new GlobalPropsHelper()
        const seometadata = globalProps.readDataAlternateLangDetails()
        return seometadata.isAlternateTagRenderEnabled
    }

    /**
     * function to push meta tag <meta property=
     * @param {SeoStructure} seoStructure
     * @param {string} propValue
     * @param {string} tagContent
     */
    static pushPropertyMetaTag(seoStructure: SeoStructure, propValue: string, tagContent: string): void {
        seoStructure.metaProp = seoStructure.metaProp || ([] as unknown as [Record<string, string>])
        seoStructure?.metaProp.push({
            property: propValue,
            content: tagContent,
        })
    }

    /**
     * function to push meta tag <meta name=
     * @param {SeoStructure} seoStructure
     * @param {string} propValue
     * @param {string} tagContent
     */
    static pushNameMetaTag(seoStructure: SeoStructure, propValue: string, tagContent: string): void {
        seoStructure.metaName = seoStructure.metaName || ([] as unknown as [Record<string, string>])
        seoStructure?.metaName.push({
            name: propValue,
            content: tagContent,
        })
    }

    /**
     * function to push og:image meta tag
     * @param {ReduxStateType} reduxState
     * @param {SeoStructure} seoStructure
     */
    static pushOgImageMetaTag(reduxState: ReduxStateType, seoStructure: SeoStructure): void {
        const imageUrl = reduxState.images
        if (imageUrl && imageUrl.length && imageUrl[0].url) {
            this.pushPropertyMetaTag(seoStructure, 'og:image', imageUrl[0].url)
        }
    }

    /**
     * function to get Seo data for Open Graph meta tags
     * @param {string} seoData
     * @returns {string}
     */
    static getSeoData(seoData: string): string {
        const siteBannerTitle = this.getSiteBannerTitle()
        return seoData + ' | ' + siteBannerTitle
    }

    /**
     * function to remove tag if exist
     * @param {string} selector
     */
    static removeTagIfExist(selector: string): void {
        const tag = document.querySelector(selector)
        if (tag) {
            tag.remove()
        }
    }
    /**
     * function to create and append meta tag
     * @param {string} attributeName
     * @param {string} attributeValue
     * @param {string} content
     */
    static createMetaTag(attributeName: string, attributeValue: string, content: string): void {
        const tag = document.createElement('meta')
        tag.setAttribute(sanitizeStringContent(attributeName), sanitizeStringContent(attributeValue))
        tag.content = sanitizeStringContent(content)
        document.getElementsByTagName('head')[0].appendChild(tag)
    }
    /**
     * Function to return canonical url for primary selling channel
     * @param {ReduxStateType} reduxState
     * @param {SpecificPageTypes} specificPages
     * @param {boolean} isPreselectedFromURL
     * @returns {string}
     */
    static canonicalUrlData = (
        reduxState: ReduxStateType,
        specificPages: SpecificPageTypes,
        isPreselectedFromURL?: boolean,
    ): string => {
        const language = libUtils.getLanguage()
        const commonContentAvailable = JSON.parse(
            sessionStorageService.getItem(`global_props_${language}`) ?? '{}',
        ) as IGlobalProps
        const canonicalUrlMap = commonContentAvailable.product?.primarySellingBannerDomain
        const canonicalDomain = canonicalUrlMap?.[reduxState.primarySellingChannel as keyof typeof canonicalUrlMap]
        const enablePaginationInCanonicalUrl = commonContentAvailable.general?.enablePaginationInCanonicalUrl
        const pageNoFromURL = Number(getParameterFromURL(queryParameters.page))
        const canonicalUrl = canonicalDomain
            ? `${canonicalDomain}${reduxState?.canonicalUrl}`
            : SeoHelper.getcanonicalUrl(reduxState, specificPages.isStorePage, isPreselectedFromURL as boolean)

        return enablePaginationInCanonicalUrl && pageNoFromURL
            ? `${canonicalUrl}?${queryParameters.page}=${pageNoFromURL}`
            : canonicalUrl
    }
    /**
     * Helper function to prepare data
     * @param {ReduxStateType} reduxState
     * @param {SpecificPageTypes} specificPages
     * @param {Vehicle} defaultVehicle
     * @param {boolean} isFitmentRequired
     * @param {TireType} shopByTireSize
     * @param {Vehicle} urlDefaultVehicle
     * @param {boolean} isPreselectedFromURL
     * @returns {SeoStructure}
     */
    static prepareData(
        reduxState: ReduxStateType,
        specificPages: SpecificPageTypes,
        defaultVehicle: Vehicle,
        isFitmentRequired: boolean,
        shopByTireSize: TireType,
        urlDefaultVehicle: Vehicle,
        isPreselectedFromURL: boolean,
    ): SeoStructure {
        const seoStructure: SeoStructure = {}
        seoStructure.isAlternateTagRenderEnabled = this.isAlternateTagRenderEnabled()

        if (Object.values(specificPages).some(page => !!page)) {
            // Tab title
            seoStructure.title = SeoHelper.getPageTitle(
                reduxState,
                specificPages,
                defaultVehicle,
                isFitmentRequired,
                shopByTireSize,
                urlDefaultVehicle,
            )
            // Open graph meta title tag
            this.pushPropertyMetaTag(seoStructure, 'og:title', this.getSeoData(seoStructure.title as string))
            // Open graph meta image tag
            if (specificPages.isPdpPage) {
                this.pushOgImageMetaTag(reduxState, seoStructure)
            }
            // Meta description
            this.prepareMetaDescription(reduxState, specificPages, seoStructure)

            if (SeoHelper.getDescription(reduxState, specificPages.isStorePage)) {
                const description = this.truncate(
                    this.getMetaDescription(reduxState, specificPages.isStorePage),
                    seoDescriptionLimit,
                )
                this.pushNameMetaTag(seoStructure, 'description', description)
                this.pushPropertyMetaTag(seoStructure, 'og:description', this.getSeoData(description))
            }

            // Meta Robot tags
            if (SeoHelper.getMetaTags(reduxState)) {
                this.pushNameMetaTag(seoStructure, 'robots', this.getMetaRobotContent(reduxState))
            }
            // href langulage tags
            seoStructure.links = seoStructure.links || ([] as unknown as [Record<string, string>])
            seoStructure?.links.push({
                href: encodeURI(SeoHelper.canonicalUrlData(reduxState, specificPages, isPreselectedFromURL)),
                rel: 'canonical',
            })
            this.getHrefLangTags(seoStructure, reduxState, isPreselectedFromURL)
        }
        return seoStructure
    }

    /**
     * function to update canonical tags
     * @param {HTMLElement} canonicalLink
     */
    static updateCanonicalLinks = (canonicalLink?: HTMLElement): void => {
        const isCanonical = (elType: string) => elType === 'canonical'
        const headDom = document.getElementsByTagName('head')[0]
        if (!canonicalLink) {
            const globalProps = new GlobalPropsHelper()
            const seometadata = globalProps.readDataAlternateLangDetails()
            const { canonical } = seometadata
            const linkTag = document.createElement('link')
            linkTag.rel = 'canonical'
            const canonicalURL = window.location.origin + canonical
            linkTag.href = sanitizeStringContent(canonicalURL)
            canonicalLink = linkTag
        }
        const existingLink = Array.from(headDom.children).find(e => {
            return isCanonical(e.getAttribute('rel') as string)
        })
        if (existingLink) {
            existingLink.remove()
        }
        headDom.appendChild(canonicalLink)
    }
    /**
     * cheking the page is brand page or not
     * @param {string} pageType
     * @returns {boolean}
     */
    static isBrandPage = (pageType: string): boolean => {
        return pageType === pageTypes.brandPage
    }
}
