import { pageTypes } from '../../config'

export const status = {
    ok: 'OK',
    fail: 'FAIL',
    success: '200',
    successCode: '206005',
    verificationExpired: '403002',
    accountLocked: 403120,
    apiRateLimitExceeded: 403048,
    tfaErrorCode: 403101,
    verificationErrorCode: 206002,
    pendingPasswordChange: 403100,
    userStatusCode: 403007,
    authorizedUser: 0,
    unAuthErrorCode: 403005,
} // Object used for status

export const emailRegEx =
    /^(?=(.{1,64}@.{1,255}))([!#$%&'*+\-/=?^_`{|}~a-zA-Z0-9}]{1,64}(\.[!#$%&'*+\-/=?^_`{|}~a-zA-Z0-9]{0,}){0,})@((\[(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}\])|([a-zA-Z0-9-]{1,63}(\.[a-zA-Z0-9-]{2,63}){1,}))$/ // Email regex value

export const gigyaScreens: Record<string, string> = {
    verificationSent: 'gigya-verification-sent-screen',
    loginScreen: 'gigya-login-screen',
    welcomeBack: 'gigya-welcome-back-screen',
    forgotPasswordSuccess: 'gigya-forgot-password-success-screen',
    forgotPassword: 'gigya-forgot-password-screen',
    securityResetScreen: 'gigya-password-change-security-screen',
    resetPasswordScreen: 'gigya-reset-password-screen',
    emailSubscribeScreen: 'gigya-subscribe-with-email-screen',
    emailSubscribeSuccessScreen: 'gigya-subscribe-thank-you-screen',
    emailSubscribeFailScreen: 'gigya-subscribe-error-screen',
    tfaVerificationScreen: 'gigya-tfa-verification-screen',
    verificationPendingScreen: 'gigya-verification-pending-screen',
    loginAttempts: 'gigya-login-attempts',
    registrationScreen: 'gigya-register-screen',
    forcedPasswordChange: 'gigya-password-change-required-screen',
    emailVerificationExpired: 'gigya-email-verification-expired',
    registrationSuccess: 'gigya-complete-registration-screen-2',
    resetPasswordSuccess: 'gigya-reset-password-success-screen',
} // Object used for gigyaAuthentication and logging

export const gigyaOperations: Record<string, string> = {
    resetPassword: '/accounts.resetPassword',
    accountInfo: '/accounts.setAccountInfo',
} // Object used for gigyaOperations

export const loginUserId = 'gigya-loginID-107508554302582130'
export const loginPasswordId = 'gigya-password-110355237210106350'
export const welcomeBackPasswordId = 'gigya-password-118078117014222480'
export const welcomeBackUserId = 'gigya-loginID-112975867754691520'
export const forgotUserId = 'gigya-textbox-loginID'
export const liteSignupUserId = 'gigya-textbox-email'
export const postalCodeId = 'gigya-textbox-96477976172004130'
export const gigyaWideModalClass = 'gigya-wide-modal'
export const gigyaScreenDialogClass = 'gigya-screen-dialog'
export const gigyaScreenContentClass = 'gigya-screen-content'
export const gigyaSignInModalDefaultContentClass = 'default-login-modal'
export const ciamButtonClass = 'ciam-button'
export const checkoutLoginClass = 'checkout-login-modal'
export const userProfileData = 'userProfileData'
export const userTriangleInfo = 'uTrnType'
export const checkoutSigninFlag = 'checkoutSignin'
export const subscribeToEmailScreenset = [
    'gigya-subscribe-error-screen',
    'gigya-subscribe-with-email-screen',
    'gigya-subscribe-thank-you-screen',
]

export const pagesForLiteProfileCall: string[] = [pageTypes.pdpPage]

export const password = 'password'

export const gigyaSignInModalContentStyleClasses: Record<string, string> = {
    checkout: 'checkout',
}

export const gigyaErrorTranslationMapping: Record<string, string> = {
    '100001': 'old_password_cannot_be_the_same_as_new_password',
    '100002': 'wrong_password',
    '403120': 'account_temporarily_locked_out',
    '403041': 'account_is_disabled',
    '401020': 'login_captcha_error',
    '401021': 'login_captcha_error',
    '403043': 'login_identifier_exists',
    '400003': 'unique_identifier_exists',
    '403044': 'sorry_we_are_not_able_to_process_your_registration',
    '403042': 'invalid_login_or_password',
    '403047': 'there_is_no_user_with_that_username_or_email',
    '400006': 'there_are_errors_in_your_form_please_try_again',
    '401030': 'old_password_cannot_be_the_same_as_new_password',
    '500026': 'network_error',
}

export const gigyaResetPasswordErrorMapping: Record<string, string> = {
    '401030': 'old_password_cannot_be_the_same_as_new_password',
    '403025': 'invalid_password_reset_token',
}

export const gigyaUpdatePasswordErrorMapping: Record<string, string> = {
    '403042': 'invalid_login_or_password',
    '400006': 'old_password_cannot_be_the_same_as_new_password',
}

export const GigyaScreenSet = 'SET_SCREEN_DATA'
export const IS_GIGYA_LOADED = 'IS_GIGYA_LOADED'
export const VALIDATE_SSO_SESSION_PENDING = 'VALIDATE_SSO_SESSION_PENDING'
export const gigyaLoginFormId = 'gigya-login-form'
export const registrationGigyaScreen = 'gigya-register-screen'
export const gigyaErrorMsgActive = 'gigya-error-msg-active'
export const gigyaErrorDisplayActive = 'gigya-error-display-active'

// remove it the future with redesigning security component
export enum selector {
    controlFormError = '.gigya-error-display.gigya-composite-control.gigya-composite-control-form-error',
    formErrorMessage = '.gigya-error-msg.gigya-form-error-msg',
}

export const SEMICOLON = ';'
export const passwordComplexity = {
    valid: 'valid',
    firstFocus: 'firstFocus',
    invalid: 'invalid',
}

export const gigPrefix = 'gig_'
export const gigyaParams = {
    sequence: 'gig_sequence',
    pageType: 'gig_pageType',
}

export const gigyaBannerIdMapping: Record<string, string> = {
    ATM: 'AT',
    MKS: 'MK',
    SC: 'SC',
    LEQ: 'LQ',
}

export const jwtPayloadData: Record<string, string> = {
    loyaltyId: 'data.loyalty.id',
    epclId: 'data.loyalty.epclId',
}
